

@media (min-width: 768px) {
  .nav-box .container,
  .nav-box .container-lg,
  .nav-box .container-md,
  .nav-box .container-sm,
  .nav-box .container-xl {
    max-width: 720px;
    width: 720px;
  }
}

@media (min-width: 992px) {
  .nav-box .container,
  .nav-box .container-lg,
  .nav-box .container-md,
  .nav-box .container-sm,
  .nav-box .container-xl {
    max-width: 960px;
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .nav-box .container,
  .nav-box .container-lg,
  .nav-box .container-md,
  .nav-box .container-sm,
  .nav-box .container-xl {
    max-width: 1200px;
    width: 1200px;
  }
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
  display: block;
}

@media (min-width: 768px) {
  .navbar .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }

  .navbar .navbar-right .dropdown-menu-left {
    right: auto;
    left: 0;
  }
}

.navbar {
  display: block;
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  padding: 0;
}

.navbar-nav .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin-top: 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.navbar .navbar-collapse.collapse {
  display: block !important;
  height: auto !important;
  padding-bottom: 0;
  overflow: visible !important;
  flex: inherit;
}

.dropdown-toggle::after {
  display: inline;
  margin: 0;
  border: none;
  vertical-align: inherit;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: block;
}

.nav > li {
  position: relative;
  display: block;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: #eee;
}

.nav > li.disabled > a {
  color: #777;
}

.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
  color: #777;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #eee;
  border-color: #337ab7;
}

.nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.nav > li > a > img {
  max-width: none;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

@media (min-width: 768px) {
  .navbar {
    border-radius: 4px;
  }
}

@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 0px;
  font-size: 18px;
  line-height: 20px;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-brand > img {
  display: block;
}

@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: -15px;
  }
}

.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.navbar-toggle:focus {
  outline: 0;
}

.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}

@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
}

.navbar-nav {
  margin: 7.5px -15px;
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }

  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }

  .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
}

@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }

  .navbar-nav > li {
    float: left;
  }

  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .navbar-left {
    float: left !important;
  }

  .navbar-right {
    float: right !important;
    margin-right: -15px;
  }

  .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after {
  display: table;
  content: " ";
}

.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after {
  clear: both;
}


nav.bootsnav .dropdown.megamenu-fw {
  position: static;
}

nav.bootsnav .container {
  position: relative;
}

nav.bootsnav .megamenu-fw .dropdown-menu {
  left: auto;
}

nav.bootsnav .megamenu-content {
  padding: 15px;
  width: 90% !important;
  margin: auto;
}

@media (min-width: 1601px) {
  nav.bootsnav .megamenu-content {
      width: 1320px !important;
  }

}

nav.bootsnav .megamenu-content .title {
    color: #333333;
    font-weight: 600;
    margin-top: 0;
    text-transform: capitalize;
    margin-bottom: 10px;
    font-size: 18px;
}

nav.bootsnav .dropdown.megamenu-fw .dropdown-menu {
  left: 0;
  right: 0;
}

.navbar .side .widget > a {
  border-radius: 30px;
}

.navbar-collapse {
  padding: 0;
}

nav.navbar .quote-btn a {
  color: #232323;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  border: 2px solid #2b5690;
  text-transform: uppercase;
  top: -2px;
  letter-spacing: 0.6px;
}

.torch-red nav.navbar .quote-btn a {
  border: 2px solid #2b5690;
}

nav.navbar .quote-btn {
  margin-top: -4px;
}


nav.navbar .navbar-brand {
  height: auto !important;
}

nav.navbar.bootsnav.logo-less .navbar-brand {
  display: none;
}

nav.navbar.navbar-default.logo-less .navbar-collapse {
  margin-left: -15px;
}

.navbar-default.navbar.navbar-sidebar.bg-dark .social-share {
  padding: 0 30px;
}

nav.bootsnav.navbar-sidebar.bg-dark ul.nav li.dropdown ul.dropdown-menu li a, 
nav.bootsnav.navbar-sidebar.bg-dark ul.nav li.dropdown a, 
nav.bootsnav.navbar-sidebar.bg-dark ul.nav li.dropdown h6 {
  color: #cccccc;
}

.attr-nav ul.cart-list li.total a {
  background: #2b5690 none repeat scroll 0 0;
  display: inline-block;
  font-weight: 600 !important;
  padding: 12px 15px;
  color: #ffffff !important;
}

.attr-nav ul.cart-list li.total a:hover {
  background: #2b5690 none repeat scroll 0 0 !important;
  color: #ffffff !important;
}

nav.navbar.bootsnav {
  background-color: rgb(255 255 255 / 97%);
  border-radius: 0;
  border: none;
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  -o-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  margin: 0;
}

nav.bg-dark.navbar.bootsnav {
  background-color: #1c1c1c;
  border: medium none !important;
  margin: 0;
}

nav.navbar.bootsnav ul.nav > li > a {
  color: #333333;
  background-color: transparent !important;
  font-size: 14px;
  letter-spacing: 0.06em;
  padding: 35px 15px;
  text-transform: uppercase;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600 !important;
}

nav.navbar.bootsnav.bg-dark ul.nav > li > a {
  color: #ffffff;
}

nav.navbar.bootsnav ul.nav li.megamenu-fw > a:hover,
nav.navbar.bootsnav ul.nav li.megamenu-fw > a:focus,
nav.navbar.bootsnav ul.nav li.active > a:hover,
nav.navbar.bootsnav ul.nav li.active > a:focus,
nav.navbar.bootsnav ul.nav li.active > a {
  background-color: transparent;
}

nav.navbar.bootsnav .navbar-toggle {
  background-color: transparent !important;
  border: none;
  padding: 0;
  font-size: 18px;
  position: relative;
  top: 15px;
}

nav.navbar.bootsnav ul.nav .dropdown-menu .dropdown-menu {
  top: 0;
  left: 100%;
}

nav.navbar.bootsnav ul.nav ul.dropdown-menu > li > a {
  white-space: normal;
}

ul.menu-col {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.menu-col li a {
  color: #333333;
}

ul.menu-col li a:hover,
ul.menu-col li a:focus {
  text-decoration: none;
}

#navbar-menu {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

nav.bootsnav.navbar-full {
  padding-bottom: 10px;
  padding-top: 10px;
}

nav.bootsnav.navbar-full .navbar-header {
  display: block;
  width: 100%;
}

nav.bootsnav.navbar-full .navbar-toggle {
  display: inline-block;
  margin-right: 0;
  position: relative;
  top: 20px;
  font-size: 24px;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

nav.bootsnav.navbar-full .navbar-collapse {
  position: fixed;
  width: 100%;
  height: 100% !important;
  top: 0;
  left: 0;
  padding: 0;
  display: none !important;
  z-index: 9;
}

nav.bootsnav.navbar-full .navbar-collapse.in,
nav.bootsnav.navbar-full .navbar-collapse.show {
  display: block !important;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full {
  overflow: auto;
}

nav.bootsnav.navbar-full .navbar-collapse .wrap-full-menu {
  display: table-cell;
  vertical-align: middle;
  background-color: #fff;
  overflow: auto;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full::-webkit-scrollbar {
  width: 0;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full::-moz-scrollbar {
  width: 0;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full::-ms-scrollbar {
  width: 0;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full::-o-scrollbar {
  width: 0;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav {
  display: block;
  width: 100%;
  overflow: auto;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav a:hover,
nav.bootsnav.navbar-full .navbar-collapse ul.nav a:focus,
nav.bootsnav.navbar-full .navbar-collapse ul.nav a {
  background-color: transparent;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav > li {
  float: none;
  display: block;
  text-align: center;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav > li > a {
  display: table;
  margin: auto;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: normal;
  font-size: 14px;
  padding: 15px 15px;
}

nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li > a .badge {
  padding: 3px 10px;
  font-weight: 600;
  margin-left: 4px;
  background: #2b5690;
  color: #ffffff;
  border-radius: 5px;
}

.torch-red nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li > a .badge {
  background: #2b5690;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav > li > a:hover {
  letter-spacing: 7px;
}

nav.bootsnav.navbar-full
  .navbar-collapse
  ul.nav
  > li.close-full-menu
  > a:hover {
  letter-spacing: normal;
}

li.close-full-menu > a {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

li.close-full-menu > a i {
  font-size: 32px;
}

li.close-full-menu {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.attr-nav {
  display: inline-block;
  float: right;
  margin-left: 50px;
}

nav.navbar.attr-border .attr-nav {
  margin-left: 30px;
  padding-left: 15px;
  min-height: 90px;
}

.navbar .attr-nav .call {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.navbar.navbar-transparent .attr-nav .call,
.navbar.navbar-transparent .attr-nav .call h5 {
  color: #ffffff;
}

.navbar .attr-nav .call i {
    font-size: 40px;
    margin-right: 20px;
    display: inline-block;
    background: linear-gradient(to right, #ff5634, #ff957f, #ff5634);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    top: 5px;
}

.torch-red .navbar .attr-nav .call i {
    color: #2b5690;
    background: transparent;
    -webkit-background-clip: inherit;
    -moz-background-clip: inherit;
    background-clip: inherit;
    -webkit-text-fill-color: inherit;
}

.navbar.navbar-transparent .attr-nav .call i {
  color: #ffffff;
  -webkit-background-clip: inherit;
  -moz-background-clip: inherit;
  background-clip: inherit;
  -webkit-text-fill-color: inherit;
  background: transparent;
}

.navbar .attr-nav .call span {
    font-size: 13px;
}

.navbar .attr-nav .call h5 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.bootsnav .side .widget.social li.facebook a {
  background: #3b5998 none repeat scroll 0 0;
}

.bootsnav .side .widget.social li.twitter a {
  background: #1da1f2 none repeat scroll 0 0;
}

.bootsnav .side .widget.social li.pinterest a {
  background: #bd081c none repeat scroll 0 0;
}

.bootsnav .side .widget.social li.g-plus a {
  background: #db4437 none repeat scroll 0 0;
}

.bootsnav .side .widget.social li.linkedin a {
  background: #0077b5 none repeat scroll 0 0;
}

.bootsnav .side .widget.social li.dribbble a {
  background: #ea4c89 none repeat scroll 0 0;
}

nav.navbar.attr-border.bootsnav.sticked .attr-nav {
  min-height: 80px;
}

.attr-nav > ul {
  padding: 0;
  margin: 0 0 -17px 0;
  list-style: none;
  display: flex;
  align-items: center;
}

nav.navbar.attr-border .attr-nav {
  margin-left: 15px;
  min-height: 90px;
  padding-left: 10px;
  position: relative;
  z-index: 1;
}

nav.navbar.attr-border .attr-nav::before {
  background: #232323 none repeat scroll 0 0;
  content: "";
  height: 30px;
  left: 0;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  width: 1px;
}

.attr-nav > ul li.dropdown ul.dropdown-menu {
  margin-top: 0;
}

.attr-nav > ul > li {
  float: left;
  display: block;
}

.attr-nav > ul > li > a {
  color: #333333;
  display: block;
  padding: 32px 0 !important;
  position: relative;
}

.attr-nav > a {
  margin-top: 25px;
}

.attr-nav > ul > li > a span.badge {
  background-color: #2b5690;
  color: #ffffff;
  height: 20px;
  line-height: 20px;
  margin-top: -5px;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 20px;
}

.torch-red .attr-nav > ul > li > a span.badge {
  background-color: #2b5690;
}

.navbar .attr-nav.multi-item {
  display: flex;
  align-items: center;
}

.navbar .attr-nav .side-menu {
  position: relative;
  float: right;
  width: 40px;
  height: 100%;
  margin-left: 16px;
  padding: 38px 0;
}

.navbar .attr-nav .side-menu span {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  height: 2px;
  width: 40px;
  background: #232323;
  transition: all 0.35s ease-in-out;
}

.navbar .attr-nav .side-menu a:hover span {
  width: 30px;
}

.navbar .attr-nav .side-menu span:nth-child(2) {
  top: 8px;
  width: 35px;
}

.navbar .attr-nav .side-menu a:hover span:nth-child(2) {
  width: 35px;
}

.navbar .attr-nav .side-menu span:nth-child(3) {
  top: 16px;
  width: 30px;
}

.navbar .attr-nav .side-menu a:hover span:nth-child(3) {
  width: 40px;
}

.navbar.white.navbar-transparent .attr-nav .side-menu span,
.navbar.white.no-background .attr-nav .side-menu span {
  background: #ffffff;
}

.color-yellow .attr-nav > ul > li > a span.badge {
  background-color: #ff9800;
}

.attr-nav > ul > li.dropdown ul.dropdown-menu {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  -moz-box-shadow: 0px 0px 0px;
  -webkit-box-shadow: 0px 0px 0px;
  -o-box-shadow: 0px 0px 0px;
  box-shadow: 0px 0px 0px;
  border: solid 1px #e0e0e0;
}

ul.cart-list {
  padding: 0 !important;
  width: 250px !important;
}

ul.cart-list > li {
  position: relative;
  border-bottom: solid 1px #efefef;
  padding: 15px 15px 23px 15px !important;
}

ul.cart-list > li > a.photo {
  padding: 0 !important;
  margin-right: 15px;
  float: left;
  display: block;
  width: 50px;
  height: 50px;
  left: 15px;
  top: 15px;
}

ul.cart-list > li img {
  border: 1px solid #e7e7e7;
  height: 50px;
  padding: 3px;
  width: 50px;
}

ul.cart-list > li > h6 {
  margin: 0;
  font-size: 14px;
}

ul.cart-list > li > h6 > a.photo {
  padding: 0 !important;
  display: block;
}

ul.cart-list > li > p {
  margin-bottom: 0;
}

ul.cart-list > li.total {
  background-color: #f5f5f5;
  padding-bottom: 15px !important;
  font-family: 'Josefin Sans', sans-serif;
}

ul.cart-list > li.total > .btn {
  display: inline-block;
  border: none !important;
  height: auto !important;
}

ul.cart-list > li .price {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
}

ul.cart-list > li .price:hover {
  box-shadow: inherit;
}

ul.cart-list > li.total > span {
  padding-top: 8px;
}

.top-search {
  background-color: #111d30;
  padding: 10px 0;
  display: none;
}
.top-search input.form-control {
  background-color: transparent;
  border: none;
  -moz-box-shadow: 0px 0px 0px;
  -webkit-box-shadow: 0px 0px 0px;
  -o-box-shadow: 0px 0px 0px;
  box-shadow: 0px 0px 0px;
  color: #fff;
  height: 40px;
  padding: 0 15px;
  border: none !important;
}

.top-search input.form-control::-webkit-input-placeholder {
  
  color: #ffffff !important;
}
.top-search input.form-control::-moz-placeholder {
  
  color: #ffffff !important;
}
.top-search input.form-control:-ms-input-placeholder {
  
  color: #ffffff !important;
}
.top-search input.form-control:-moz-placeholder {
  
  color: #ffffff !important;
}

.top-search .input-group-addon {
  background-color: transparent;
  border: none;
  color: #fff;
  padding-left: 0;
  padding-right: 0;
  padding: 12px 0;
}

.top-search .input-group-addon.close-search {
  cursor: pointer;
}

body {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.side {
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: -500px;
  width: 500px;
  padding: 50px;
  padding-top: 120px;
  height: 100%;
  display: block;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 9;
  box-shadow: 0 15px 40px -5px rgba(0, 0, 0, 0.1);
}

.side.on {
  right: -16px;
}

.body-overlay {
  background: rgba(0, 0, 0, 0.65) none repeat scroll 0 0;
  bottom: 0;
  height: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  transition: opacity 0.4s ease 0.8s, height 0s ease 1.2s;
  z-index: 490;
}

body.on-side .body-overlay {
  height: 100%;
  transition: height 0s ease 0s, opacity 0.4s ease 0s;
}

body.on-side .body-overlay {
  opacity: 1;
  top: 0;
}

.side .close-side {
  border-radius: 50%;
  color: #232323;
  float: right;
  font-size: 30px;
  font-weight: 400;
  height: 40px;
  line-height: 38px;
  position: relative;
  text-align: center;
  top: -85px;
  width: 40px;
  z-index: 2;
}

.color-yellow .side .close-side {
  color: #ff9800;
}

.side.barber .close-side {
  border-color: #bc9355;
  color: #bc9355;
}

.navbar .side .widget.social li {
  display: inline-block;
}

.navbar .side .widget li a {
  color: #232323;
}

.navbar .side .widget li a:hover {
  color: #2b5690;
}

.torch-red .navbar .side .widget li a:hover {
  color: #2b5690;
}

nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle:hover::after {
  content: "\f106";
}

nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle::after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  margin-left: 5px;
  margin-top: -2px;
  display: inline-block;
  border: none;
  font-weight: 900;
  transition: all 0.35s ease-in-out;
}

.navbar .side .widget.social li a {
  color: #0a1015;
  display: inline-block;
  font-weight: 600;
  height: 45px;
  line-height: 45px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0;
  text-align: center;
  width: 45px;
  border-radius: 50%;
  border: 1px solid #e7e7e7;
}

.navbar .side .widget.social li a:hover {
  background: #0a1015 none repeat scroll 0 0;
  color: #ffffff;
  border-color: #0a1015;
}

.navbar.color-yellow .side .widget.social li a:hover {
  background: #ff9800 none repeat scroll 0 0;
}

.navbar .side .widget li {
  display: block;
  font-size: 15px;
  margin-bottom: 15px;
}

.navbar .side .widget.opening-hours li {
  border-bottom: 1px solid #e7e7e7;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.navbar .side .widget.opening-hours li:last-child {
  border: medium none;
  margin: 0;
  padding: 0;
}

.navbar .side .widget li span {
  float: right;
}

.navbar .side.barber .widget li i {
  background: #bc9355 none repeat scroll 0 0;
}

.bootsnav .side .widget.search input {
  border: 1px solid #e7e7e7;
  box-shadow: inherit;
  width: 100%;
}

.navbar .side .widget li:last-child {
  margin: 0;
}

.navbar .side .widget h4 {
  display: block;
  font-weight: 600;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
}

.side .widget.address ul li {
  display: flex;
  margin-bottom: 15px;
}

.side .widget.address ul li:last-child {
  margin-bottom: 0;
}

.side .widget.address ul li i {
  display: inline-block;
  font-size: 30px;
  margin-right: 15px;
  color: #2b5690;
}

.torch-red .side .widget.address ul li i {
  color: #2b5690;
}

.side .widget.address ul li p {
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 0;
  color: #232323;
  font-family: 'Josefin Sans', sans-serif;
}

.side .widget.address ul li strong {
  font-weight: 400;
}

.side .widget.newsletter form {
  position: relative;
  z-index: 1;
  width: 100%;
}

.side .widget.newsletter form .input-group.stylish-input-group {
  position: relative;
}

.side .widget.newsletter form input,
.side .widget.newsletter form input:focus {
  width: 100%;
  background: transparent;
  padding: 15px;
  color: #232323;
  border-radius: inherit;
  border: 1px solid #e7e7e7;
  min-height: 60px;
}

.side .widget.newsletter form span.input-group-addon {
  width: 60px;
  position: absolute;
  right: 0;
  border-left: 1px solid #e7e7e7;
  min-height: 60px;
  z-index: 9;
}

.side .widget.newsletter form span.input-group-addon button {
  position: absolute;
  height: 57px;
  top: 2px;
  right: 2px;
  width: 56px;
  border: none;
  background: #ffffff;
  border-radius: 5px;
  color: #2b5690;
  font-size: 30px;
}

.torch-red .side .widget.newsletter form span.input-group-addon button {
  color: #2b5690;
}

.navbar .side .widget .profile-thumb img {
  border: 2px solid #e7e7e7;
  height: 150px;
  margin-top: 10px;
  padding: 3px;
  width: 150px;
}

.side .widget {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
}

.side .widget:last-child {
  margin-bottom: 0;
}

.side img {
  margin-bottom: 20px;
}

.side .widget .title {
  margin-bottom: 15px;
}

.side .widget ul.link {
  padding: 0;
  margin: 0;
  list-style: none;
}

.side .widget ul.link li a {
  color: #2b5690;
  display: block;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 5px 0;
  text-transform: uppercase;
}

.torch-red .side .widget ul.link li a {
  color: #2b5690;
}

.side .widget ul.link li a:focus,
.side .widget ul.link li a:hover {
  color: #fff;
  text-decoration: none;
}

nav.navbar.bootsnav .share {
  padding: 0 30px;
  margin-bottom: 30px;
}

nav.navbar.bootsnav .share ul {
  display: inline-block;
  padding: 0;
  margin: 0 0 -7px 0;
  list-style: none;
}

nav.navbar.bootsnav .share ul > li {
  float: left;
  display: block;
  margin-right: 5px;
}

nav.navbar.bootsnav .share ul > li > a {
  border-radius: 3px;
  display: inline-block;
  margin-right: 20px;
  text-align: center;
  vertical-align: middle;
}

nav.navbar.bootsnav .share.dark ul > li > a {
  background-color: #2b5690;
  color: #ffffff;
}

nav.navbar.bootsnav.color-yellow .share.dark ul > li > a {
  background-color: #ff9800;
}

nav.navbar.bootsnav .share ul > li > a:hover {
  color: #2b5690;
}

.torch-red nav.navbar.bootsnav .share ul > li > a:hover {
  color: #2b5690;
}

nav.navbar.bootsnav.color-yellow .share ul > li > a:hover {
  color: #ff9800;
}

nav.navbar.bootsnav.navbar-fixed {
  display: block;
  position: fixed;
  width: 100%;
  z-index: 100;
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  -o-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  top: 0;
}

nav.navbar.bootsnav.navbar-fixed.nav-box.no-background {
  background: #ffffff none repeat scroll 0 0;
  top: 50px;
  width: auto;
  left: auto;
}

nav.navbar.bootsnav.navbar-fixed.nav-box.no-background ul.nav > li > a,
nav.navbar.bootsnav.navbar-fixed.nav-box.no-background .attr-nav > ul > li > a {
  color: #333333;
}

nav.navbar.bootsnav.navbar-fixed.nav-box {
  left: 0;
  top: 0;
  width: 100%;
}

nav.navbar.bootsnav.navbar-fixed.no-background {
  display: block;
  position: absolute;
  width: 100%;
  z-index: 100;
  box-shadow: 0 0 0;
  -moz-box-shadow: 0 0 0;
  -webkit-box-shadow: 0 0 0;
  -o-box-shadow: 0 0 0;
}

nav.navbar.bootsnav {
  z-index: 100;
}

.wrap-sticky {
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.wrap-sticky nav.navbar.bootsnav {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  margin: 0;
}

.wrap-sticky nav.navbar.bootsnav.sticked {
  position: fixed;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  top: 0;
}

@media (min-width: 1024px) and (max-width: 1400px) {
  body.wrap-nav-sidebar .wrapper .container {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  nav.navbar.bootsnav ul.nav > li > a {
    padding: 35px 12px !important;
  }

  nav.navbar.bootsnav.sticked ul.nav > li > a {
    padding: 30px 12px !important;
  }
}

@media (min-width: 1024px) {

  
  
  nav.navbar.navbar-default.bootsnav.navbar-fixed-light.no-background {
    background: #ffffff;
    box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  }

  nav.navbar.navbar-default.bootsnav.navbar-fixed-light a.navbar-brand {
    position: relative;
    z-index: 1;
    padding-left: 0;
    padding-right: 30px;
  }

  nav.navbar.bootsnav ul.nav .dropdown-menu .dropdown-menu {
    margin-top: -2px;
  }

  .nav-box.no-background .container.nav-container {
    box-shadow: 0 0 10px #cccccc;
    padding: 0 30px;
  }

  nav.navbar.bootsnav
    ul.nav.navbar-right
    .dropdown-left
    .dropdown-menu
    .dropdown-menu {
    left: -200px;
  }

  nav.navbar.bootsnav ul.nav.navbar-right .dropdown-menu {
    right: auto;
  }

  nav.navbar.bootsnav ul.nav.navbar-right .dropdown-left .dropdown-menu {
    right: 0;
    left: auto;
  }

  nav.navbar.bootsnav.navbar-default.navbar-fixed.navbar-transparent.inc-topbar {
    margin-top: 65px;
    transition: all 0.35s ease-in-out 0s;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  nav.navbar.bootsnav.navbar-fixed.no-background.top-pad {
    margin-top: 65px;
  }

  .navbar.bootsnav .attr-nav.inc-bg ul {
    background: #ffffff;
    padding: 4px 24px;
    position: relative;
    top: 20px;
    box-shadow: 0 0 10px #cccccc;
    border-radius: 30px;
  }

  .navbar.bootsnav .attr-nav.inc-bg ul li {
    padding: 0;
  }

  .navbar.bootsnav .attr-nav.inc-bg ul li.side-menu {
    text-align: right;
    margin-left: 5px;
  }

  .navbar.bootsnav .attr-nav.inc-bg ul li a {
    display: block;
  }

  nav.navbar.bootsnav.navbar-default.navbar-fixed.inc-topbar {
    margin-top: 0;
  }

  nav.navbar.bootsnav ul.nav > li > a {
    font-size: 13px;
    letter-spacing: 0.06em;
    padding: 35px 15px;
    text-transform: uppercase;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600 !important;
  }

  nav.navbar.bootsnav ul.nav > li > a.active {
    color: #333;
  }

  .torch-red nav.navbar.bootsnav ul.nav > li > a.active {
    color: #333;
  }

  nav.navbar.bootsnav.active-bg ul.nav > li > a.active {
    background: #2b5690 !important;
  }

  .torch-red nav.navbar.bootsnav.active-bg ul.nav > li > a.active {
    background: #2b5690 !important;
  }

  nav.navbar.bootsnav.active-border ul.nav > li > a.active {
    position: relative;
    z-index: 1;
  }

  nav.navbar.bootsnav.active-border ul.nav > li > a.active::before {
    top: -2px;
    position: absolute;
    left: 10%;
    content: "";
    height: 0px;
    width: 80%;
    border-bottom: 3px solid #2b5690;
  }

  .torch-red nav.navbar.bootsnav.active-border ul.nav > li > a.active::before {
    border-bottom: 3px solid #2b5690;
  }

  nav.navbar.bootsnav.small-pad ul.nav > li > a {
    padding: 35px 15px !important;
  }

  nav.navbar.bootsnav.small-pad.logo-less ul.nav > li > a {
    padding: 30px 15px !important;
  }

  nav.bootsnav.small-pad .attr-nav > ul > li {
    padding: 25px 0;
  }

  nav.bootsnav.small-pad.logo-less .attr-nav > ul > li {
    padding: 20px 0;
  }

  nav.navbar.small-pad .attr-nav {
    min-height: inherit;
  }

  nav.navbar.bootsnav .navbar-brand {
    padding: 15px 15px;
  }

  nav.navbar.bootsnav.brand-light .navbar-brand {
    background: #ffffff;
    border-radius: 30px;
  }

  nav.navbar.bootsnav ul.nav > li.active > a {
    color: #2b5690;
    border-radius: 30px;
  }

  .torch-red nav.navbar.bootsnav ul.nav > li.active > a {
    color: #2b5690;
  }

  nav.bootsnav.navbar-sidebar ul.nav > li > a i {
    display: inline-block;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    background: #ecf6fa;
    color: #232323;
    border-radius: 50%;
    margin-right: 5px;
  }

  nav.bootsnav.navbar-sidebar ul.nav > li.active > a i {
    background: #2b5690;
    color: #ffffff;
  }

  .torch-red nav.bootsnav.navbar-sidebar ul.nav > li.active > a i {
    background: #2b5690;
  }

  nav.navbar.bootsnav.barber ul.nav > li.active > a {
    color: #bc9355;
  }

  nav.navbar.bootsnav.active-full ul.nav > li > a.active,
  nav.navbar.bootsnav.active-full ul.nav > li > a:hover {
    background: #2b5690 none repeat scroll 0 0 !important;
    color: #ffffff;
  }

  .torch-red nav.navbar.bootsnav.active-full ul.nav > li > a.active,
  .torch-red nav.navbar.bootsnav.active-full ul.nav > li > a:hover {
    background: #2b5690 none repeat scroll 0 0 !important;
  }

  nav.navbar.bootsnav.active-full.color-yellow ul.nav > li > a.active,
  nav.navbar.bootsnav.active-full.color-yellow ul.nav > li > a:hover {
    background: #ff9800 none repeat scroll 0 0 !important;
  }

  nav.navbar.bootsnav.active-full ul.nav > li.active > a:hover {
    color: #ffffff;
  }

  nav.navbar.bootsnav ul.nav > li > a:hover {
    color: #2b5690;
  }

  .torch-red nav.navbar.bootsnav ul.nav > li > a:hover {
    color: #2b5690;
  }

  nav.navbar.bootsnav.color-yellow ul.nav > li > a:hover {
    color: #ff9800;
  }

  nav.navbar.bootsnav.barber ul.nav > li > a:hover {
    color: #bc9355;
  }

  nav.op-nav.bootsnav ul.nav.navbar-nav li a {
    text-transform: uppercase;
  }

  .social-links.sl-default a {
    border: 1px solid #ffffff;
    color: #ffffff;
    display: inline-block;
    float: left;
    height: 30px;
    line-height: 30px;
    margin-bottom: 5px;
    margin-right: 5px;
    text-align: center;
    width: 30px;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu {
    background: #fff none repeat scroll 0 0;
    border: 1px solid transparent;
    border-radius: 2px;
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.15);
    padding: 0;
    width: 250px;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu.cart-list {
    left: auto;
    min-width: 300px;
    right: 0;
    border: none !important;
    margin-top: -5px;
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.15);
  }

  nav.navbar.bootsnav.navbar-sticky.sticked
    li.dropdown
    ul.dropdown-menu.cart-list {
    margin-top: -8px;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li a:hover,
  nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li a:hover {
    background-color: transparent;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li > a {
    padding: 12px 15px;
    border-bottom: solid 1px #f5f5f5;
    color: #333333;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li:last-child > a {
    border-bottom: none;
  }

  nav.navbar.bootsnav ul li.dropdown ul.dropdown-menu li a {
    color: #333333;
    display: block;
    font-size: 14px;
    margin-bottom: 0;
    padding: 10px 15px;
    text-align: left;
    text-transform: capitalize;
    width: 100%;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600 !important;
  }

  nav.navbar.bootsnav ul.navbar-right li.dropdown ul.dropdown-menu li a:hover {
    color: #2b5690;
  }

  .torch-red nav.navbar.bootsnav ul.navbar-right li.dropdown ul.dropdown-menu li a:hover {
    color: #2b5690;
  }

  nav.navbar.bootsnav.color-yellow
    ul.navbar-right
    li.dropdown
    ul.dropdown-menu
    li
    a:hover {
    color: #ff9800;
  }

  nav.navbar.bootsnav ul.navbar-left li.dropdown ul.dropdown-menu li a:hover {
    color: #2b5690;
  }

  .torch-red nav.navbar.bootsnav ul.navbar-left li.dropdown ul.dropdown-menu li a:hover {
    color: #2b5690;
  }

  nav.navbar.bootsnav.color-yellow
    ul.navbar-left
    li.dropdown
    ul.dropdown-menu
    li
    a:hover {
    color: #ff9800;
  }

  nav.navbar.bootsnav
    ul.navbar-right
    li.dropdown.dropdown-left
    ul.dropdown-menu
    li
    a {
    text-align: right;
  }

  nav.navbar.bootsnav
    li.dropdown
    ul.dropdown-menu
    li.dropdown
    > a.dropdown-toggle:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    float: right;
    content: "\f105";
    margin-top: 0;
  }

  nav.navbar.bootsnav
    ul.navbar-right
    li.dropdown
    ul.dropdown-menu
    li.dropdown
    > a.dropdown-toggle:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    float: right;
    content: "\f105";
    margin-top: 0;
  }

  nav.navbar.bootsnav
    ul.navbar-right
    li.dropdown.dropdown-left
    ul.dropdown-menu
    li.dropdown
    > a.dropdown-toggle:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    float: left;
    content: "\f104";
    margin-top: 0;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu ul.dropdown-menu {
    top: -1px;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content {
    padding: 0 15px !important;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content > li {
    padding: 50px 0 45px;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content.tabbed {
    padding: 0;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content.tabbed > li {
    padding: 0;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .col-menu {
    padding: 0 30px;
    margin: 0 -0.5px;
    border-left: solid 1px #f0f0f0;
    border-right: solid 1px #f0f0f0;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .col-menu:first-child {
    border-left: none;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .col-menu:last-child {
    border-right: none;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .content {
    display: none;
  }

  nav.navbar.bootsnav
    ul.dropdown-menu.megamenu-content
    .content
    ul.menu-col
    li
    a {
    border-bottom: medium none;
    color: #333333;
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    padding: 8px 0;
    text-align: left;
    text-transform: capitalize;
    width: 100%;
  }

  nav.navbar.bootsnav
    ul.dropdown-menu.megamenu-content
    .content
    ul.menu-col
    li
    a:hover {
    padding-left: 10px;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .content
    ul.menu-col
    li
    a:hover {
    color: #2b5690;
  }

  nav.navbar.bootsnav.color-yellow
    ul.dropdown-menu.megamenu-content
    .content
    ul.menu-col
    li
    a:hover {
    color: #ff9800;
  }

  nav.navbar.bootsnav.on ul.dropdown-menu.megamenu-content .content {
    display: block !important;
    height: auto !important;
  }

  nav.navbar.bootsnav.no-background {
    background-color: transparent;
    border: none;
  }

  nav.navbar.bootsnav.navbar-transparent .attr-nav {
    padding-left: 15px;
    margin-left: 30px;
  }

  nav.navbar.bootsnav.navbar-transparent.white {
    background-color: rgba(255, 255, 255, 0.3);
    border-bottom: solid 1px #bbb;
  }

  nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark,
  nav.navbar.bootsnav.navbar-transparent.dark {
    background-color: rgba(0, 0, 0, 0.3);
    border-bottom: solid 1px #555;
  }

  nav.navbar.bootsnav.navbar-transparent.white .attr-nav {
    border-left: solid 1px #bbb;
  }

  nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark .attr-nav,
  nav.navbar.bootsnav.navbar-transparent.dark .attr-nav {
    border-left: solid 1px #555;
  }

  nav.navbar.bootsnav.no-background.white .attr-nav > ul > li > a,
  nav.navbar.bootsnav.navbar-transparent.white .attr-nav > ul > li > a,
  nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a,
  nav.navbar.bootsnav.no-background.white ul.nav > li > a {
    color: #fff;
  }

  nav.navbar.bootsnav.navbar-transparent.dark .attr-nav > ul > li > a,
  nav.navbar.bootsnav.navbar-transparent.dark ul.nav > li > a {
    color: #eee;
  }

  nav.navbar.bootsnav.navbar-fixed.navbar-transparent .logo-scrolled,
  nav.navbar.bootsnav.navbar-fixed.no-background .logo-scrolled {
    display: none;
  }

  nav.navbar.bootsnav.navbar-fixed.navbar-transparent .logo-display,
  nav.navbar.bootsnav.navbar-fixed.no-background .logo-display {
    display: block;
  }

  nav.navbar.bootsnav.navbar-fixed .logo-display {
    display: none;
  }

  nav.navbar.bootsnav.navbar-fixed .logo-scrolled {
    display: block;
  }

  .attr-nav > ul > li.dropdown ul.dropdown-menu {
    margin-top: 0;
    margin-left: 55px;
    width: 250px;
    left: -250px;
  }

  nav.navbar.bootsnav.menu-center .container {
    position: relative;
  }

  nav.navbar .container-full {
    width: 90%;
    margin: auto;
    position: relative;
  }

  nav.navbar.bootsnav.menu-center ul.nav.navbar-center {
    float: right;
    margin: 0 auto;
    display: table;
    table-layout: fixed;
  }

  nav.navbar.bootsnav.menu-center .attr-nav {
    right: 15px;
  }

  nav.bootsnav.navbar-brand-top .navbar-header {
    display: block;
    width: 100%;
    text-align: center;
  }

  nav.bootsnav.navbar-brand-top ul.nav > li.dropdown > ul.dropdown-menu {
    margin-top: 0px;
  }

  nav.bootsnav.navbar-brand-top
    ul.nav
    > li.dropdown.megamenu-fw
    > ul.dropdown-menu {
    margin-top: 0;
  }

  nav.bootsnav.navbar-brand-top .navbar-header .navbar-brand {
    display: inline-block;
    float: none;
    margin: 0;
  }

  nav.bootsnav.navbar-brand-top .navbar-collapse {
    text-align: center;
  }

  nav.bootsnav.navbar-brand-top ul.nav {
    display: inline-block;
    float: none;
    margin: 0 0 -5px 0;
  }

  nav.bootsnav.brand-center .navbar-header {
    display: block;
    width: 100%;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
  }

  nav.bootsnav.brand-center .navbar-brand {
    display: inline-block;
    float: none;
  }

  nav.bootsnav.brand-center.center-side .navbar-brand {
    display: inline-block;
    float: none;
    padding: 15px 0;
  }

  nav.bootsnav.brand-center .navbar-collapse {
    text-align: center;
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }

  nav.bootsnav.brand-center ul.nav > li.dropdown > ul.dropdown-menu {
    margin-top: 0px;
  }

  nav.bootsnav.brand-center
    ul.nav
    > li.dropdown.megamenu-fw
    > ul.dropdown-menu {
    margin-top: 0;
  }

  nav.bootsnav.brand-center .navbar-collapse .col-half {
    width: 50%;
    float: left;
    display: block;
  }

  nav.bootsnav.brand-center .navbar-collapse .col-half.left {
    text-align: right;
    padding-right: 100px;
  }

  nav.bootsnav.brand-center .navbar-collapse .col-half.right {
    text-align: left;
    padding-left: 100px;
  }

  nav.bootsnav.brand-center ul.nav {
    float: none !important;
    margin-bottom: -5px !important;
    display: inline-block !important;
  }

  nav.bootsnav.brand-center ul.nav.navbar-right {
    margin: 0;
  }

  nav.bootsnav.brand-center.center-side .navbar-collapse .col-half.left {
    text-align: left;
    padding-right: 100px;
  }

  nav.bootsnav.brand-center.center-side .navbar-collapse .col-half.right {
    text-align: right;
    padding-left: 100px;
  }

  body.wrap-nav-sidebar .wrapper {
    padding-left: 260px;
    overflow-x: hidden;
  }

  nav.bootsnav.navbar-sidebar {
    position: fixed;
    width: 260px;
    overflow: hidden;
    left: 0;
    padding: 0 0 0 0 !important;
    background: #fff;
    -moz-box-shadow: 0 0 10px #cccccc;
    -webkit-box-shadow: 0 0 10px #cccccc;
    -o-box-shadow: 0 0 10px #cccccc;
    box-shadow: 0 0 10px #cccccc;
  }

  nav.bootsnav.navbar-sidebar.bg-dark {
    background: #fff none repeat scroll 0 0;
    -moz-box-shadow: 1px 0 6px 0 #040914;
    -webkit-box-shadow: 1px 0 6px 0 #040914;
    -o-box-shadow: 1px 0 6px 0 #040914;
    box-shadow: 1px 0 6px 0 #040914;
    left: 0;
    overflow: hidden;
    padding: 0 !important;
    position: fixed;
    width: 260px;
  }

  nav.bootsnav.navbar-sidebar.bg-dark {
    background-color: #1c1c1c;
  }

  nav.bootsnav.navbar-sidebar .scroller {
    width: 280px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  nav.bootsnav.navbar-sidebar .container-fluid,
  nav.bootsnav.navbar-sidebar .container {
    padding: 0 !important;
  }

  nav.bootsnav.navbar-sidebar .navbar-header {
    float: none;
    display: block;
    width: 260px;
    padding: 10px 15px;
    margin: 10px 0 0 0 !important;
  }

  nav.bootsnav.navbar-sidebar .navbar-collapse {
    padding: 0 !important;
    width: 260px;
  }

  nav.bootsnav.navbar-sidebar ul.nav {
    float: none;
    display: block;
    width: 100%;
    padding: 0 15px !important;
    margin: 0 0 30px 0;
  }

  nav.bootsnav.navbar-sidebar ul.nav li {
    float: none !important;
    margin-bottom: 10px;
  }

  nav.bootsnav.navbar-sidebar.bg-dark ul.nav > li {
    border-bottom: 1px solid #0f1a20;
  }

  nav.bootsnav.navbar-sidebar ul.nav > li > a {
    padding: 8px 15px !important;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
  }

  nav.bootsnav.navbar-sidebar.bg-dark ul.nav > li > a {
    color: #ffffff;
  }

  nav.bootsnav.navbar-sidebar ul.nav > li > a:hover {
    color: #2b5690;
  }

  .torch-red nav.bootsnav.navbar-sidebar ul.nav > li > a:hover {
    color: #2b5690;
  }

  nav.bootsnav.navbar-sidebar.color-yellow ul.nav > li > a:hover {
    color: #ff9800;
  }

  nav.bootsnav.navbar-sidebar ul.nav > li.dropdown > a:after {
    float: right;
  }

  nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu {
    left: 100%;
    top: 0;
    position: relative !important;
    left: 0 !important;
    width: 100% !important;
    height: auto !important;
    background-color: transparent;
    border: none !important;
    padding: 0;
    -moz-box-shadow: 0px 0px 0px;
    -webkit-box-shadow: 0px 0px 0px;
    -o-box-shadow: 0px 0px 0px;
    box-shadow: 0px 0px 0px;
  }

  nav.bootsnav.navbar-sidebar ul.nav .megamenu-content .col-menu {
    border: none !important;
  }

  nav.bootsnav.navbar-sidebar ul.nav > li.dropdown > ul.dropdown-menu {
    margin-bottom: 15px;
  }

  nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu {
    padding-left: 15px;
    float: none;
    margin-bottom: 0;
  }

  nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu li a {
    padding: 10px 15px;
    color: #333333;
    border: none;
  }

  nav.bootsnav.navbar-sidebar
    ul.nav
    li.dropdown
    ul.dropdown-menu
    ul.dropdown-menu {
    padding-left: 15px;
    margin-top: 0;
  }

  nav.bootsnav.navbar-sidebar
    ul.nav
    li.dropdown
    ul.dropdown-menu
    li.dropdown
    > a:before {
    font-family: "FontAwesome";
    content: "\f105";
    float: right;
  }

  nav.bootsnav.navbar-sidebar
    ul.nav
    li.dropdown.on
    ul.dropdown-menu
    li.dropdown.on
    > a:before {
    content: "\f107";
  }

  nav.bootsnav.navbar-sidebar ul.dropdown-menu.megamenu-content > li {
    padding: 0 !important;
  }

  nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu {
    display: block;
    float: none !important;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu .title {
    padding: 7px 0;
    text-transform: none;
    font-weight: 400;
    letter-spacing: 0px;
    margin-bottom: 0;
    cursor: pointer;
    color: #333333;
    text-transform: uppercase;
  }

  nav.bootsnav.navbar-sidebar
    .dropdown
    .megamenu-content
    .col-menu
    .title:before {
    font-family: "FontAwesome";
    content: "\f105";
    float: right;
  }

  nav.bootsnav.navbar-sidebar
    .dropdown
    .megamenu-content
    .col-menu.on
    .title:before {
    content: "\f107";
  }

  nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu {
    border: none;
  }

  nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu .content {
    padding: 0 0 0 15px;
  }

  nav.bootsnav.navbar-sidebar
    .dropdown
    .megamenu-content
    .col-menu
    ul.menu-col
    li
    a {
    padding: 3px 0 !important;
  }
}



@media (max-width: 1023px) {

  .showlist{
    display: block !important;
  }
  .attr-nav {
    margin-right: 0;
  }

  .navbar .attr-nav .call,
  .navbar .attr-nav .call h5 {
    color: #232323 !important;
  }
  
  .navbar .attr-nav .call{
    margin-top: 10px !important;
  }

  .wrap-sticky nav.navbar.bootsnav .attr-nav .call {
    margin-top: 13px;
}

.navbar .attr-nav .call i {
    font-size: 40px;
    margin-right: 20px;
    display: inline-block;
    background: linear-gradient(to right, #ff5634, #ff957f, #ff5634) !important;
    -webkit-background-clip: text !important;
    -moz-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.torch-red .navbar .attr-nav .call i {
    background: transparent !important;
    -webkit-background-clip: inherit !important;
    -moz-background-clip: inherit !important;
    background-clip: inherit !important;
    -webkit-text-fill-color: inherit !important;
    color: #2b5690;
}

  nav.bootsnav.navbar-default.small-pad .top-search {
    top: 70px;
  }

  nav.navbar.bootsnav {
    box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04) !important;
  }

  nav.navbar.bootsnav .navbar-brand {
    display: inline-block;
    float: none !important;
    margin: 0 !important;
  }

  nav.navbar.bootsnav .navbar-header {
    float: none;
    display: block;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  nav.navbar.bootsnav .navbar-toggle {
    display: inline-block;
    float: left;
    margin-right: -200px;
    margin-top: 15px;
  }

  nav.navbar.bootsnav.navbar-fixed .navbar-toggle {
    margin-top: 10px;
  }

  nav.navbar.bootsnav.navbar-fixed img {
    position: relative;
    top: 5px;
  }

  nav.navbar.nav-box.bootsnav.navbar-fixed img {
    top: 5px;
  }

  nav.navbar.bootsnav.small-pad {
    min-height: 60px;
  }

  nav.navbar.bootsnav.attr-border .navbar-header {
    min-height: 60px;
  }

  nav.navbar.bootsnav.logo-less .navbar-brand {
    display: inline-block;
  }

  .navbar-brand > img {
    height: 60px !important;
  }

  nav.navbar.bootsnav.navbar-transparent.pad-top {
    background: #ffffff none repeat scroll 0 0 !important;
    border-bottom: none !important;
    margin-top: 0 !important;
  }

  nav.navbar.bootsnav.attr-border .navbar-header {
    border-bottom: 1px solid #e7e7e7;
  }

  nav.navbar.attr-border .attr-nav,
  nav.navbar.bootsnav.sticked.attr-border .attr-nav {
    height: 60px;
    min-height: 60px;
  }

  nav.navbar .quote-btn {
    margin-top: 0;
  }

  .top-search {
    top: 70px;
  }

  nav.bootsnav.navbar-default.small-pad.logo-less .top-search {
    top: 70px;
  }

  .navbar.navbar-fixed .top-search {
    top: 85px;
  }

  nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle::after {
    display: none;
  }

  .attr-nav > ul > li {
    padding: 19px 0 !important;
  }

  .navbar.navbar-fixed .attr-nav > ul > li {
    padding: 23px 0 !important;
  }

  header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a {
    margin-left: 0;
  }

  nav.bootsnav.navbar-default.info-topbar .navbar-header {
    display: block !important;
  }

  nav.bootsnav.navbar-default.info-topbar ul li a.active::after {
    display: none;
  }

  nav.bootsnav.navbar-default.info-topbar ul li a::after {
    display: none;
  }

  .attr-nav > ul > li > a {
    padding: 17px 0 !important;
  }

  .top-bar-area .logo {
    display: none;
  }

  .top-bar-area.shadow::after {
    display: none;
  }

  .top-bar-area .info {
    text-align: center;
  }

  nav.navbar.shadow.navbar-inverse.bootsnav.navbar-transparent.dark,
  nav.navbar.shadow.bootsnav.navbar-transparent.dark {
    background-color: #ffffff !important;
    border-bottom: medium none navy;
  }

  .navbar-default.bootsnav.navbar-sidebar.bg-dark .social-share {
    padding: 20px 0;
  }

  .attr-nav > ul li.dropdown ul.dropdown-menu {
    margin-top: -8px !important;
  }

  nav.navbar.bootsnav.no-background.wt-bar {
    background-color: #ffffff;
    margin: 0;
    position: relative;
    top: 0 !important;
  }

  .topbar-area.com.bg-transparent {
    border-bottom: 1px solid #e7e7e7 !important;
    position: relative !important;
  }

  .topbar-area.com.bg-transparent.text-light a {
    color: #333333 !important;
  }

  nav.bg-dark.navbar.bootsnav .navbar-toggle {
    color: #ffffff;
  }

  nav.bg-dark.navbar.bootsnav ul.nav > li > a {
    background-color: transparent;
    color: #333333 !important;
  }

  nav.navbar.bootsnav ul.nav li.dropdown > ul.dropdown-menu {
    box-shadow: inherit !important;
  }

  nav.navbar.bootsnav ul.nav li.dropdown > ul.dropdown-menu li > a {
    border-bottom: 1px solid #e7e7e7 !important;
    font-weight: 600;
  }

  nav.navbar.bootsnav ul.nav li.dropdown > ul.dropdown-menu li:hover > a {
    background-color: transparent !important;
    color: #2b5690 !important;
  }

  .torch-red nav.navbar.bootsnav ul.nav li.dropdown > ul.dropdown-menu li:hover > a {
    color: #2b5690 !important;
  }

  nav.navbar.bootsnav.color-yellow
    ul.nav
    li.dropdown
    > ul.dropdown-menu
    li:hover
    > a {
    color: #ff9800 !important;
  }

  nav.bg-dark.navbar.bootsnav ul.nav li.dropdown > ul.dropdown-menu li a {
    background: transparent none repeat scroll 0 0 !important;
    border-bottom: 1px solid #e7e7e7;
    color: #333333 !important;
  }

  nav.bg-dark.navbar.bootsnav ul.nav li.dropdown > ul.dropdown-menu li a:hover {
    color: #5cb85c !important;
  }

  nav.navbar.bootsnav .navbar-collapse.collapse.in,
  nav.navbar.bootsnav .navbar-collapse.collapse.show {
    background: #ffffff none repeat scroll 0 0;
    display: block;
    margin-bottom: 30px;
  }

  nav.bg-dark.navbar.bootsnav .dropdown .megamenu-content .col-menu .title {
    border-bottom: 1px solid #e0e0e0;
    font-size: 14px;
  }

  nav.navbar.bootsnav .navbar-collapse {
    border: none;
    margin-bottom: 0;
  }

  nav.navbar.bootsnav.no-full .navbar-collapse {
    max-height: 350px;
    overflow-y: auto !important;
  }

  nav.navbar.bootsnav .navbar-collapse.collapse {
    display: none !important;
  }

  nav.navbar.bootsnav .navbar-collapse.collapse.in,
  nav.navbar.bootsnav .navbar-collapse.collapse.show {
    display: block !important;
  }

  nav.navbar.bootsnav .navbar-nav {
    float: none !important;
    padding-left: 30px;
    padding-right: 30px;
    margin: 0px;
  }

  nav.navbar.bootsnav .navbar-nav > li {
    float: none;
  }

  nav.navbar.bootsnav li.dropdown a.dropdown-toggle:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f105";
    float: right;
    font-size: 16px;
    margin-left: 10px;
  }

  nav.navbar.bootsnav li.dropdown.on > a.dropdown-toggle:before {
    content: "\f107";
  }

  nav.navbar.bootsnav .navbar-nav > li > a {
    display: block;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 13px 0 !important;
    border-top: solid 1px #e0e0e0;
    margin-bottom: -1px;
  }

  nav.navbar.bootsnav .navbar-nav > li:first-child > a {
    border-top: none;
  }

  nav.navbar.bootsnav
    ul.navbar-nav.navbar-left
    > li:last-child
    > ul.dropdown-menu {
    border-bottom: solid 1px #e0e0e0;
  }

  nav.navbar.bootsnav ul.nav li.dropdown li a.dropdown-toggle {
    float: none !important;
    position: relative;
    display: block;
    width: 100%;
  }

  nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu {
    width: 100%;
    position: relative !important;
    background-color: transparent;
    float: none;
    border: none;
    padding: 0 0 0 15px !important;
    margin: 0 0 -1px 0 !important;
    -moz-box-shadow: 0px 0px 0px;
    -webkit-box-shadow: 0px 0px 0px;
    -o-box-shadow: 0px 0px 0px;
    box-shadow: 0px 0px 0px;
    -moz-border-radius: 0px 0px 0px;
    -webkit-border-radius: 0px 0px 0px;
    -o-border-radius: 0px 0px 0px;
    border-radius: 0px 0px 0px;
  }

  nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu > li > a {
    border-bottom: 1px solid #e0e0e0;
    color: #333333;
    display: block;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 0;
    text-transform: capitalize;
    width: 100%;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600 !important;
  }

  nav.navbar.bootsnav ul.nav ul.dropdown-menu li a:hover,
  nav.navbar.bootsnav ul.nav ul.dropdown-menu li a:focus {
    background-color: transparent;
  }

  nav.navbar.bootsnav ul.nav ul.dropdown-menu ul.dropdown-menu {
    float: none !important;
    left: 0;
    padding: 0 0 0 15px;
    position: relative;
    background: transparent;
    width: 100%;
  }

  nav.navbar.bootsnav
    ul.nav
    ul.dropdown-menu
    li.dropdown.on
    > ul.dropdown-menu {
    display: inline-block;
    margin-top: -10px;
  }

  nav.navbar.bootsnav
    li.dropdown
    ul.dropdown-menu
    li.dropdown
    > a.dropdown-toggle:after {
    display: none;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu .title {
    padding: 13px 15px 13px 0;
    line-height: 24px;
    text-transform: none;
    font-weight: 400;
    letter-spacing: 0px;
    margin-bottom: 0;
    cursor: pointer;
    border-bottom: solid 1px #e0e0e0;
    color: #333333;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu ul > li > a {
    display: block;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 10px 0;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu .title::before {
    content: "\f105";
    float: right;
    font-family: "Font Awesome 5 Free";
    font-size: 16px;
    font-weight: 900;
    margin-left: 10px;
    position: relative;
    right: -15px;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu:last-child .title {
    border-bottom: none;
  }

  nav.navbar.bootsnav
    .dropdown
    .megamenu-content
    .col-menu.on:last-child
    .title {
    border-bottom: solid 1px #e0e0e0;
  }

  nav.navbar.bootsnav
    .dropdown
    .megamenu-content
    .col-menu:last-child
    ul.menu-col
    li:last-child
    a {
    border-bottom: none;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu.on .title:before {
    content: "\f107";
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu .content {
    padding: 0 0 0 15px;
  }

  nav.bootsnav.brand-center .navbar-collapse {
    display: block;
  }

  nav.bootsnav.brand-center ul.nav {
    margin-bottom: 0px !important;
  }

  nav.bootsnav.brand-center .navbar-collapse .col-half {
    width: 100%;
    float: none;
    display: block;
  }

  nav.bootsnav.brand-center .navbar-collapse .col-half.left {
    margin-bottom: 0;
  }

  nav.bootsnav .megamenu-content {
    padding: 0;
  }

  nav.bootsnav .megamenu-content .col-menu {
    padding-bottom: 0;
  }

  nav.bootsnav .megamenu-content .title {
    cursor: pointer;
    display: block;
    padding: 10px 15px;
    margin-bottom: 0;
    font-weight: normal;
  }

  nav.bootsnav .megamenu-content .content {
    display: none;
  }

  .attr-nav {
    position: absolute;
    right: 60px;
  }

  .attr-nav > ul {
    padding: 0;
    margin: 0 -15px -7px 0;
  }

  .attr-nav > ul > li > a {
    padding: 16px 15px 15px;
  }

  .attr-nav > ul > li.dropdown > a.dropdown-toggle:before {
    display: none;
  }

  .attr-nav > ul > li.dropdown ul.dropdown-menu {
    margin-top: 2px;
    margin-left: 55px;
    width: 250px;
    left: -250px;
    border-top: none;
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.15);
  }

  .top-search .container {
    padding: 0 45px;
  }

  nav.bootsnav.navbar-full ul.nav {
    margin-left: 0;
  }

  nav.bootsnav.navbar-full ul.nav > li > a {
    border: none;
  }

  nav.bootsnav.navbar-full .navbar-brand {
    float: left !important;
    padding-left: 0;
  }

  nav.bootsnav.navbar-full .navbar-toggle {
    display: inline-block;
    float: right;
    margin-right: 0;
    margin-top: 10px;
    top: 0;
  }

  nav.bootsnav.navbar-full .navbar-header {
    padding-left: 15px;
    padding-right: 15px;
  }

  nav.navbar.bootsnav.navbar-sidebar .share {
    padding: 30px 15px;
    margin-bottom: 0;
  }

  nav.navbar.bootsnav .megamenu-content.tabbed {
    padding-left: 0 !important;
  }

  nav.navbar.bootsnav .tabbed > li {
    padding: 25px 0;
    margin-left: -15px !important;
  }

  body > .wrapper {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  body.side-right > .wrapper {
    margin-left: 280px;
    margin-right: -280px !important;
  }

  nav.navbar.bootsnav.navbar-mobile .navbar-collapse {
    position: fixed;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    display: block;
    background: #fff;
    z-index: 99;
    width: 280px;
    height: 100% !important;
    left: -280px;
    top: 0;
    padding: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  nav.navbar.bootsnav.navbar-mobile .navbar-collapse.in,
  nav.navbar.bootsnav.navbar-mobile .navbar-collapse.show {
    left: 0;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav {
    width: 293px;
    padding-right: 0;
    padding-left: 15px;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav > li > a {
    padding: 15px 15px;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav ul.dropdown-menu > li > a {
    padding-right: 15px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav ul.dropdown-menu .col-menu .title {
    padding-right: 30px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  nav.navbar.bootsnav.navbar-mobile
    ul.nav
    ul.dropdown-menu
    .col-menu
    ul.menu-col
    li
    a {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  nav.navbar.bootsnav.navbar-mobile .navbar-collapse [class*=" col-"] {
    width: 100%;
  }

  nav.navbar.bootsnav.navbar-fixed .logo-scrolled {
    display: block !important;
  }

  nav.navbar.bootsnav.navbar-fixed .logo {
    display: block !important;
  }

  nav.navbar.bootsnav.navbar-fixed .logo-display {
    display: none !important;
  }

  nav.navbar.bootsnav.navbar-mobile .tab-menu,
  nav.navbar.bootsnav.navbar-mobile .tab-content {
    width: 100%;
    display: block;
  }
}

@media (max-width: 767px) {
  nav.navbar.bootsnav .navbar-header {
    padding-left: 15px;
    padding-right: 15px;
  }

  nav.navbar.bootsnav .navbar-nav {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0;
  }

  .attr-nav > ul {
    margin-right: -25px;
  }

  .attr-nav > ul > li > a {
    padding: 16px 10px 15px;
    padding-left: 0 !important;
  }

  .attr-nav > ul > li.dropdown ul.dropdown-menu {
    left: -275px;
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.15);
  }

  .top-search .container {
    padding: 0 15px;
  }

  nav.bootsnav.navbar-full .navbar-collapse {
    left: 15px;
  }

  nav.bootsnav.navbar-full .navbar-header {
    padding-right: 0;
  }

  nav.bootsnav.navbar-full .navbar-toggle {
    margin-right: -15px;
  }

  nav.bootsnav.navbar-full ul.nav > li > a {
    font-size: 18px !important;
    line-height: 14px !important;
    padding: 10px 10px !important;
  }

  nav.navbar.bootsnav.navbar-sidebar .share {
    padding: 30px 15px !important;
  }

  nav.navbar.bootsnav.navbar-sidebar .share {
    padding: 30px 0 !important;
    margin-bottom: 0;
  }

  nav.navbar.bootsnav.navbar-mobile.navbar-sidebar .share {
    padding: 30px 15px !important;
    margin-bottom: 0;
  }

  body.side-right > .wrapper {
    margin-left: 280px;
    margin-right: -280px !important;
  }

  nav.navbar.bootsnav.navbar-mobile .navbar-collapse {
    margin-left: 0;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav {
    margin-left: -15px;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav {
    border-top: solid 1px #fff;
  }

  li.close-full-menu {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}

@media (min-width: 480px) and (max-width: 640px) {
  nav.bootsnav.navbar-full ul.nav {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.navbar-brand > img {
  display: initial;
  height: 60px;
}

.attr-nav > ul > li {
  padding: 25px 0;
  font-size: 18px;
}

nav .attr-nav > ul > li.contact {
  padding: 31px 0;
  margin-left: 30px;
}

nav.navbar-transparent .attr-nav > ul > li.contact {
  color: #ffffff;
  margin-left: 0;
}

.attr-nav > ul > li.contact i {
  margin-right: 10px;
  font-size: 25px;
  position: relative;
  float: left;
  color: #2b5690;
  top: 3px;
}

.torch-red .attr-nav > ul > li.contact i {
  color: #2b5690;
}

nav.navbar-transparent .attr-nav > ul > li.contact i {
  color: #ffffff;
}

.attr-nav > ul > li > a {
  font-size: 16px;
  padding: 8px 10px !important;
}

.attr-nav > ul.link > li > a {
  padding: 4px 0 4px 10px !important;
  font-size: 18px;
  text-transform: capitalize;
  color: #2b5690;
}

.torch-red .attr-nav > ul.link > li > a {
  color: #2b5690;
}

.attr-nav > ul.link > li > a i {
  margin-right: 3px;
}

.attr-nav > ul > li.side-menu > a {
  padding-right: 0 !important;
}

.attr-nav > ul > li.quote-btn > a {
  padding: 8px 35px !important;
  margin-left: 5px;
  border-radius: 30px;
}

.attr-nav.menu li a {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.attr-nav.menu li {
  margin-left: 10px;
}

.attr-nav.menu li:last-child a {
  background: #2b5690 none repeat scroll 0 0;
  border-radius: 30px;
  color: #ffffff !important;
  padding: 5px 20px !important;
}

.torch-red .attr-nav.menu li:last-child a {
  background: #2b5690 none repeat scroll 0 0;
}

.color-yellow .attr-nav.menu li:last-child a {
  background: #ff9800 none repeat scroll 0 0;
}

nav.bootsnav.navbar-default.info-topbar .attr-nav ul li a {
  font-size: 16px;
  margin-left: 25px;
  margin-right: 0;
}

nav.bootsnav.navbar-default.info-topbar.sticked .attr-nav ul li a {
  margin-right: 0;
}

nav.navbar.bootsnav.bg-dark .attr-nav > ul > li > a {
  color: #ffffff;
}

ul.cart-list > li.total > .btn {
  color: #232323;
  padding: 10px 25px !important;
}

@media (min-width: 1024px) {
  nav.navbar ul.nav > li > a {
    padding: 30px 15px;
    font-weight: 300;
  }

  nav.navbar .navbar-brand {
    margin-top: 0;
  }

  nav.navbar .navbar-brand {
    margin-top: 0;
  }

  nav.navbar li.dropdown ul.dropdown-menu {
    border-top: solid 5px;
  }

  nav.navbar-center .navbar-brand {
    margin: 0 !important;
  }

  nav.navbar-brand-top .navbar-brand {
    margin: 10px !important;
  }

  nav.navbar-full .navbar-brand {
    position: relative;
  }

  nav.navbar-sidebar ul.nav,
  nav.navbar-sidebar .navbar-brand {
    margin-bottom: 30px;
    margin-top: 20px;
  }

  nav.navbar-sidebar ul.nav > li > a {
    padding: 10px 15px;
    font-weight: bold;
  }

  nav.navbar.bootsnav.navbar-transparent.white {
    background: transparent none repeat scroll 0 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  nav.navbar.bootsnav.navbar-transparent.border-box {
    border: none;
    box-shadow: inherit;
  }

  nav.navbar.bootsnav.navbar-transparent.border-box .container.nav-container {
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0;
  }

  nav.navbar.bootsnav.navbar-transparent.border-box
    .container.nav-container
    .attr-nav {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }

  nav.navbar.bootsnav.navbar-transparent.white.barber {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(35, 35, 35, 0.3);
  }

  nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark,
  nav.navbar.bootsnav.navbar-transparent.dark {
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    box-shadow: 0 -1px 0 0 rgba(255, 255, 255, 0.1) inset;
  }

  nav.navbar.bootsnav.navbar-transparent.white .attr-nav {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    min-height: 90px;
  }

  nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark .attr-nav,
  nav.navbar.bootsnav.navbar-transparent.dark .attr-nav {
    border-left: solid 1px rgba(255, 255, 255, 0.1);
    min-height: 90px;
  }

  nav.navbar.bootsnav.no-background.white .attr-nav > ul > li > a,
  nav.navbar.bootsnav.navbar-transparent.white .attr-nav > ul > li > a,
  nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a,
  nav.navbar.bootsnav.no-background.white ul.nav > li > a {
    color: #fff;
  }

  nav.navbar.bootsnav.navbar-transparent.dark .attr-nav > ul > li > a,
  nav.navbar.bootsnav.navbar-transparent.dark ul.nav > li > a {
    color: #eee;
  }
}

@media (max-width: 992px) {
  nav.navbar .navbar-brand {
    margin-top: 0;
    position: relative;
  }

  nav.navbar.navbar-sticky .navbar-brand {
    top: 0;
  }

  nav.navbar.navbar-sidebar .navbar-brand {
    top: 0;
  }

  nav.navbar .navbar-brand img.logo {
    height: 30px;
	margin:10px;
  }

  .attr-nav > ul > li > a {
    padding: 20px 15px 15px;
  }

  nav.navbar.navbar-mobile ul.nav > li > a {
    padding: 15px 15px;
  }

  nav.navbar.navbar-mobile ul.nav ul.dropdown-menu > li > a {
    padding-right: 15px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  nav.navbar.navbar-mobile ul.nav ul.dropdown-menu .col-menu .title {
    padding-right: 30px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  nav.navbar.navbar-mobile ul.nav ul.dropdown-menu .col-menu ul.menu-col li a {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  nav.navbar-full .navbar-brand {
    top: 0;
    padding-top: 10px;
  }
}

nav.navbar.navbar-inverse {
  background-color: #222;
  border-bottom: solid 1px #303030;
}

nav.navbar.navbar-inverse ul.cart-list > li.total > .btn {
  border-bottom: solid 1px #222 !important;
}

nav.navbar.navbar-inverse ul.cart-list > li.total .pull-right {
  color: #fff;
}

nav.navbar.navbar-inverse.megamenu
  ul.dropdown-menu.megamenu-content
  .content
  ul.menu-col
  li
  a,
nav.navbar.navbar-inverse ul.nav > li > a {
  color: #eee;
}

nav.navbar.navbar-inverse ul.nav > li.dropdown > a {
  background-color: #222;
}

nav.navbar.navbar-inverse li.dropdown ul.dropdown-menu > li > a {
  color: #999;
}

nav.navbar.navbar-inverse ul.nav .dropdown-menu h1,
nav.navbar.navbar-inverse ul.nav .dropdown-menu h2,
nav.navbar.navbar-inverse ul.nav .dropdown-menu h3,
nav.navbar.navbar-inverse ul.nav .dropdown-menu h4,
nav.navbar.navbar-inverse ul.nav .dropdown-menu h5,
nav.navbar.navbar-inverse ul.nav .dropdown-menu h6 {
  color: #fff;
}

nav.navbar.navbar-inverse .form-control {
  background-color: #333;
  border-color: #303030;
  color: #fff;
}

nav.navbar.navbar-inverse .attr-nav > ul > li > a {
  color: #eee;
}

nav.navbar.navbar-inverse .attr-nav > ul > li.dropdown ul.dropdown-menu {
  background-color: #222;
  border-left: solid 1px #303030;
  border-bottom: solid 1px #303030;
  border-right: solid 1px #303030;
}

nav.navbar.navbar-inverse ul.cart-list > li {
  border-bottom: solid 1px #303030;
  color: #eee;
}

nav.navbar.navbar-inverse ul.cart-list > li img {
  border: solid 1px #303030;
}

nav.navbar.navbar-inverse ul.cart-list > li.total {
  background-color: #333;
}

nav.navbar.navbar-inverse .share ul > li > a {
  background-color: #555;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-menu {
  border-right: solid 1px #303030;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li > a {
  border-bottom: solid 1px #303030;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-content {
  border-left: solid 1px #303030;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li > a:hover,
nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li > a:focus,
nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li.active > a {
  background-color: #333 !important;
}

nav.navbar-inverse.navbar-full ul.nav > li > a {
  border: none;
}

nav.navbar-inverse.navbar-full .navbar-collapse .wrap-full-menu {
  background-color: #222;
}

nav.navbar-inverse.navbar-full .navbar-toggle {
  background-color: #222 !important;
  color: #333333;
}

@media (min-width: 1024px) {
  nav.navbar.navbar-inverse ul.nav .dropdown-menu {
    background-color: #222 !important;
    border-left: solid 1px #303030 !important;
    border-bottom: solid 1px #303030 !important;
    border-right: solid 1px #303030 !important;
  }

  nav.navbar.navbar-inverse li.dropdown ul.dropdown-menu > li > a {
    border-bottom: solid 1px #303030;
  }

  nav.navbar.navbar-inverse ul.dropdown-menu.megamenu-content .col-menu {
    border-left: solid 1px #303030;
    border-right: solid 1px #303030;
  }

  nav.navbar.navbar-inverse.navbar-transparent.dark {
    background-color: rgba(0, 0, 0, 0.3);
    border-bottom: solid 1px #999;
  }

  nav.navbar.navbar-inverse.navbar-transparent.dark .attr-nav {
    border-left: solid 1px #999;
  }

  nav.navbar.navbar-inverse.no-background.white .attr-nav > ul > li > a,
  nav.navbar.navbar-inverse.navbar-transparent.dark .attr-nav > ul > li > a,
  nav.navbar.navbar-inverse.navbar-transparent.dark ul.nav > li > a,
  nav.navbar.navbar-inverse.no-background.white ul.nav > li > a {
    color: #fff;
  }

  nav.navbar.navbar-inverse.no-background.dark .attr-nav > ul > li > a,
  nav.navbar.navbar-inverse.no-background.dark .attr-nav > ul > li > a,
  nav.navbar.navbar-inverse.no-background.dark ul.nav > li > a,
  nav.navbar.navbar-inverse.no-background.dark ul.nav > li > a {
    color: #3f3f3f;
  }
}

@media (max-width: 992px) {
  nav.navbar.navbar-inverse .navbar-toggle {
    color: #eee;
    background-color: #222 !important;
  }

  nav.navbar.navbar-inverse .navbar-nav > li > a {
    border-top: solid 1px #303030;
    border-bottom: solid 1px #303030;
  }

  nav.navbar.navbar-inverse ul.nav li.dropdown ul.dropdown-menu > li > a {
    color: #999;
    border-bottom: solid 1px #303030;
  }

  nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu .title {
    border-bottom: solid 1px #303030;
    color: #eee;
  }

  nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu ul > li > a {
    border-bottom: solid 1px #303030;
    color: #999 !important;
  }

  nav.navbar.navbar-inverse
    .dropdown
    .megamenu-content
    .col-menu.on:last-child
    .title {
    border-bottom: solid 1px #303030;
  }

  nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul {
    border-top: solid 1px #303030;
  }

  nav.navbar.navbar-inverse.navbar-mobile .navbar-collapse {
    background-color: #222;
  }
}

@media (max-width: 767px) {
  nav.navbar.navbar-inverse.navbar-mobile ul.nav {
    border-top: solid 1px #222;
  }
}

@media (min-width: 1024px) {
  .bootsnav.navbar-full.no-background .navbar-toggle {
    color: #fff;
  }
}

nav.bootsnav.navbar-full .navbar-toggle i {
  font-size: 18px;
  color: #9a9a9a;
  font-weight: bold;
  transition: 0.3s ease;
}

.navbar-toggle-txt {
  font-size: 13px;
  position: relative;
  top: -3px;
  font-weight: bold;
  color: #9a9a9a;
  transition: 0.3s ease;
}

nav.bootsnav.navbar-full .navbar-toggle:hover i,
nav.bootsnav.navbar-full .navbar-toggle:hover span {
  color: #222;
  transition: 0.3s ease;
}

.top-search .input-group .form-control::-webkit-input-placeholder {
  
  color: #666666;
  opacity: 1;
}

.top-search .input-group .form-control::-moz-placeholder {
  
  color: #666666;
  opacity: 1;
}

.top-search .input-group .form-control::-ms-input-placeholder {
  
  color: #666666;
  opacity: 1;
}

.top-search .input-group .form-control::-moz-placeholder {
  
  color: #666666;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  nav.navbar.bootsnav.navbar-fixed.nav-box.no-background {
    left: 0;
    top: 0;
    width: 100%;
  }
}

nav.bootsnav.navbar-sidebar ul.nav > li > a i.fas.fa-user,
nav.bootsnav.navbar-sidebar ul.nav > li > a i.fas.fa-file-alt {
  font-weight: 600;
}

@media (max-width: 1023px) {
  .navbar.white.navbar-transparent .attr-nav .side-menu span,
  .navbar.white.no-background .attr-nav .side-menu span {
    background: #232323;
  }

  .attr-nav ul.social {
    margin-right: 0;
  }
}@charset "UTF-8";

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

@-webkit-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}



@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  30% {
    -webkit-transform: scaleX(1.25) scaleY(0.75);
    transform: scaleX(1.25) scaleY(0.75);
  }

  40% {
    -webkit-transform: scaleX(0.75) scaleY(1.25);
    transform: scaleX(0.75) scaleY(1.25);
  }

  60% {
    -webkit-transform: scaleX(1.15) scaleY(0.85);
    transform: scaleX(1.15) scaleY(0.85);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes rubberBand {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  30% {
    -webkit-transform: scaleX(1.25) scaleY(0.75);
    -ms-transform: scaleX(1.25) scaleY(0.75);
    transform: scaleX(1.25) scaleY(0.75);
  }

  40% {
    -webkit-transform: scaleX(0.75) scaleY(1.25);
    -ms-transform: scaleX(0.75) scaleY(1.25);
    transform: scaleX(0.75) scaleY(1.25);
  }

  60% {
    -webkit-transform: scaleX(1.15) scaleY(0.85);
    -ms-transform: scaleX(1.15) scaleY(0.85);
    transform: scaleX(1.15) scaleY(0.85);
  }

  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
}

@keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  40% {
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  80% {
    -webkit-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.swing {
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }

  100% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }

  100% {
    -webkit-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}



@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }

  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }

  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
  }

  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }

  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }

  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(.3);
    transform: scale(.3);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  70% {
    -webkit-transform: scale(.9);
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(.3);
    -ms-transform: scale(.3);
    transform: scale(.3);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }

  70% {
    -webkit-transform: scale(.9);
    -ms-transform: scale(.9);
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  80% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
  }

  80% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  80% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }

  80% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }

  80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    transform: translateX(-30px);
  }

  80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  80% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  80% {
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  25% {
    -webkit-transform: scale(.95);
    transform: scale(.95);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.3);
    transform: scale(.3);
  }
}

@keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  25% {
    -webkit-transform: scale(.95);
    -ms-transform: scale(.95);
    transform: scale(.95);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.3);
    -ms-transform: scale(.3);
    transform: scale(.3);
  }
}

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

@keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

@keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}

@keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  80% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -ms-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  80% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
    -ms-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -ms-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

.animated.flip {
  -webkit-backface-visibility: visible;
  -ms-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

.flipInY {
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

.flipOutY {
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
}

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

@keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

@keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}

@keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
}

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}

@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}

@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }

  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px);
  }

  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}

@keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

@-webkit-keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

@keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(700px);
    transform: translateY(700px);
    opacity: 0;
  }
}

@keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    -webkit-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40% {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(700px);
    -ms-transform: translateY(700px);
    transform: translateY(700px);
    opacity: 0;
  }
}

.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}



@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}

@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}



@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg);
  }
}

@keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    -ms-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg);
  }
}

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(.3);
    transform: scale(.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(.3);
    -ms-transform: scale(.3);
    transform: scale(.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale(.1) translateY(-2000px);
    transform: scale(.1) translateY(-2000px);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(.475) translateY(60px);
    transform: scale(.475) translateY(60px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale(.1) translateY(-2000px);
    -ms-transform: scale(.1) translateY(-2000px);
    transform: scale(.1) translateY(-2000px);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(.475) translateY(60px);
    -ms-transform: scale(.475) translateY(60px);
    transform: scale(.475) translateY(60px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}

@-webkit-keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale(.1) translateX(-2000px);
    transform: scale(.1) translateX(-2000px);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(.475) translateX(48px);
    transform: scale(.475) translateX(48px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale(.1) translateX(-2000px);
    -ms-transform: scale(.1) translateX(-2000px);
    transform: scale(.1) translateX(-2000px);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(.475) translateX(48px);
    -ms-transform: scale(.475) translateX(48px);
    transform: scale(.475) translateX(48px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}

@-webkit-keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale(.1) translateX(2000px);
    transform: scale(.1) translateX(2000px);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(.475) translateX(-48px);
    transform: scale(.475) translateX(-48px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale(.1) translateX(2000px);
    -ms-transform: scale(.1) translateX(2000px);
    transform: scale(.1) translateX(2000px);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(.475) translateX(-48px);
    -ms-transform: scale(.475) translateX(-48px);
    transform: scale(.475) translateX(-48px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
}

@-webkit-keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale(.1) translateY(2000px);
    transform: scale(.1) translateY(2000px);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(.475) translateY(-60px);
    transform: scale(.475) translateY(-60px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale(.1) translateY(2000px);
    -ms-transform: scale(.1) translateY(2000px);
    transform: scale(.1) translateY(2000px);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(.475) translateY(-60px);
    -ms-transform: scale(.475) translateY(-60px);
    transform: scale(.475) translateY(-60px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
}

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    opacity: 0;
    -webkit-transform: scale(.3);
    transform: scale(.3);
  }

  100% {
    opacity: 0;
  }
}

@keyframes zoomOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  50% {
    opacity: 0;
    -webkit-transform: scale(.3);
    -ms-transform: scale(.3);
    transform: scale(.3);
  }

  100% {
    opacity: 0;
  }
}

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale(.475) translateY(-60px);
    transform: scale(.475) translateY(-60px);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.1) translateY(2000px);
    transform: scale(.1) translateY(2000px);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale(.475) translateY(-60px);
    -ms-transform: scale(.475) translateY(-60px);
    transform: scale(.475) translateY(-60px);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.1) translateY(2000px);
    -ms-transform: scale(.1) translateY(2000px);
    transform: scale(.1) translateY(2000px);
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
}

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale(.475) translateX(42px);
    transform: scale(.475) translateX(42px);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.1) translateX(-2000px);
    transform: scale(.1) translateX(-2000px);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale(.475) translateX(42px);
    -ms-transform: scale(.475) translateX(42px);
    transform: scale(.475) translateX(42px);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.1) translateX(-2000px);
    -ms-transform: scale(.1) translateX(-2000px);
    transform: scale(.1) translateX(-2000px);
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
  }
}

.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
}

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale(.475) translateX(-42px);
    transform: scale(.475) translateX(-42px);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.1) translateX(2000px);
    transform: scale(.1) translateX(2000px);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale(.475) translateX(-42px);
    -ms-transform: scale(.475) translateX(-42px);
    transform: scale(.475) translateX(-42px);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.1) translateX(2000px);
    -ms-transform: scale(.1) translateX(2000px);
    transform: scale(.1) translateX(2000px);
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center;
  }
}

.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
}

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale(.475) translateY(60px);
    transform: scale(.475) translateY(60px);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.1) translateY(-2000px);
    transform: scale(.1) translateY(-2000px);
    -webkit-transform-origin: center top;
    transform-origin: center top;
  }
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale(.475) translateY(60px);
    -ms-transform: scale(.475) translateY(60px);
    transform: scale(.475) translateY(60px);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.1) translateY(-2000px);
    -ms-transform: scale(.1) translateY(-2000px);
    transform: scale(.1) translateY(-2000px);
    -webkit-transform-origin: center top;
    -ms-transform-origin: center top;
    transform-origin: center top;
  }
}

.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
}