    -webkit-transform: scale(.475) translateX(-42px);
    -ms-transform: scale(.475) translateX(-42px);
    transform: scale(.475) translateX(-42px);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  
​
  100% {
    opacity: 0;
    -webkit-transform: scale(.1) translateX(2000px);
    -ms-transform: scale(.1) translateX(2000px);
    transform: scale(.1) translateX(2000px);
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center;
  }

​
.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
}
​
@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale(.475) translateY(60px);
    transform: scale(.475) translateY(60px);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
​
  100% {
    opacity: 0;
    -webkit-transform: scale(.1) translateY(-2000px);
    transform: scale(.1) translateY(-2000px);
    -webkit-transform-origin: center top;
    transform-origin: center top;
  }
}
​
@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale(.475) translateY(60px);
    -ms-transform: scale(.475) translateY(60px);
    transform: scale(.475) translateY(60px);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
​
  100% {
    opacity: 0;
    -webkit-transform: scale(.1) translateY(-2000px);
    -ms-transform: scale(.1) translateY(-2000px);
    transform: scale(.1) translateY(-2000px);
    -webkit-transform-origin: center top;
    -ms-transform-origin: center top;
    transform-origin: center top;
  }
}
​
.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
}